/* eslint-disable */
import React from 'react'
import Header from "../components/header.js";
import GeneralFooter from '../components/generalFooter';
import Layout from '../components/layout'
import { graphql } from 'gatsby'

class NotFoundPage extends React.Component {
  render() {
    const retailers = this.props.data.allNodeStore.edges
    return (
    <Layout className="about-page page-wrapper">
      <Header retailers={retailers}></Header>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      <GeneralFooter />
    </Layout>
    )
  }
}

export default NotFoundPage

export const query = graphql`
query NotFound {
  allNodeStore {
    edges {
      node {
        id
        title
        relationships {
          field_image {
            localFile {
              childImageSharp {
                resolutions(width: 143) {
                  src
                }
              }
            }
          }
          field_state {
            name
          }
        }
      }
    }
  }
}
`
